/* ==================== HEADER ==================== */

div.header {
  border-bottom: 1px solid black;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #ebedf3;
}

div.header .header-text {
  padding-left: 1em;
}

div.header h1 {
  margin-bottom: -1px;
  font-size: 3.5em;
  line-height: 0.75;
  padding: 0 0.25em;
  width: auto;
  background-color: #ebedf3;
}

div.header .mobile h1 {
  font-size: 2rem;
}

div.header h1:hover {
  cursor: pointer;
}

div.avatar-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.25em;
  margin-bottom: auto;
  margin-right: 0.25em;
}

img.avatar {
  width: 7em;
  border-radius: 100%;
}

/* ==================== SUBHEADER ==================== */

div.subheader {
  padding: 1.5em 1em 1em 2em;
}

div.subheader .full-stack-developer {
  font-size: 1.25em;
  margin-bottom: 0.25em;
}

div.subheader .one-year-experience {
  font-size: 0.8em;
}

div.subheader .skills {
  display: flex;
  align-items: center;
}

div.subheader .skills p {
  margin-right: 1em;
}

/* ==================== TICKER ANIMATION ==================== */

:root {
  --animationDistance: -100px;
  --animationDuration: 6s;
}

.wrapper {
  position: relative;
  white-space: nowrap;
  padding: 0;
  overflow: hidden;
  max-width: 900px;
  margin-left: auto;
  &:before {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 1;
    content: "";
    background: linear-gradient(
      90deg,
      #fff,
      transparent 25px,
      transparent calc(100% - 25px),
      #fff
    );
  }
}

.inner {
  animation-duration: var(--animationDuration);
  animation-timing-function: linear;
}

.inner.moving {
  animation-name: moveticker;
}

.element {
  display: inline-block;
  padding: 0 20px;
}

@keyframes moveticker {
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(var(--animationDistance));
  }
}
