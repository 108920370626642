div.navpanel {
  min-height: 100vh;
  height: 100%;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  background-color: #0d0d0d;
}

div.navpanel-content {
  position: sticky;
  top: 30px;
}

div.navpanel a,
div.navpanel-mobile a {
  text-decoration: none;
  font-size: 0.75em;
  margin-bottom: 0.5em;
  padding: 0.5em;
  color: white;
}

div.navpanel a p,
div.navpanel-mobile a p {
  margin-bottom: 0;
}

div.navpanel svg,
div.navpanel-mobile svg {
  margin-right: 0.5em;
}

div.navlink-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5em;
}

div.navlink-container a.navlink {
  font-size: 1em;
  margin-bottom: 0;
  border-radius: 0.4em;
}

div.navlink-container a.navlink.active {
  color: #107eff;
  background-color: #ffffff11;
}

/* ============================ MOBILE ============================  */

.navpanel-mobile {
  padding: 0;
  color: white;
  background-color: black;
  padding: 1rem;
  position: fixed;
  z-index: 1000;
}

.navpanel-mobile button {
  background-color: transparent;
  border: none;
}

.navpanel-mobile button svg {
  margin: 0;
  color: white;
}

.navpanel-mobile .card {
  background-color: black;
}
