div.blog-post-container {
  padding: 2em;
}

h6.blog-title {
  font-size: 2em;
}

p.blog-text {
  margin: 2em 0;
}

div.blog-output {
  border: 1px solid grey;
  border-radius: 1em;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
}

.blog-code {
  border-radius: 0.5em;
  margin: 1em 0;
}