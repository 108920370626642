div.animated-card-container {
  padding: 3em 2em;
}

div.animated-card {
  border: 2px solid #000;
  border-radius: 0.5em;
  display: flex;
  margin: 0 1em;
  background: linear-gradient(to right, #107eff 50%, transparent 0);
  background-size: 200% 100%;
  background-position: right;
  margin-bottom: 2em;
}

div.animated-card.mobile {
  margin-left: 0;
}

div.animated-card:hover {
  animation: makeItfadeIn 0.5s 0s forwards;
  cursor: pointer;
}

div.animated-card p {
  margin-top: 10em;
  margin-left: 1em;
  font-size: 1.5em;
}

@keyframes makeItfadeIn {
  100% {
    background-position: left;
    color: #fff;
  }
}

div.about {
  padding: 0.5em 2em;
}
