/* 

LIGHTEST GRAY: #EBEDF3
LIGHT GRAY: #D2D4D9
MEDIUM GRAY: #ACAEB4
BLUE: #107EFF
ALMOST BLACK: #0D0D0D

*/

/* ==================== HEADERS ==================== */

/* .light.header {
  background-color: #EBEDF3;
}

.light.header h1 {
  background-color: #EBEDF3;
} */

.light.subheader {
  border-bottom: 1px solid #acaeb4;
}

.light .full-stack-developer {
  color: #107eff;
}

.light .one-year-experience {
  color: #acaeb4;
}

.light .skills {
  color: #acaeb4;
}

/* ==================== PROJECTS ==================== */

div.project-header hr {
  color: #0d0d0d;
  font-weight: 600;
}

div.project-container hr {
  color: #acaeb4;
}

.light p.project-dates {
  color: #acaeb4;
}

/* ==================== RESUME ==================== */

.light div.skills-list .row {
  border-bottom: 1px solid rgb(230, 230, 230);
}

.light div.skills-list p.skill {
  background-color: #ebedf3;
}

.light div.work-exp .title {
  color: #107eff;
}

.light div.work-exp {
  background-color: #ebedf3;
}
