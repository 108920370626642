div.project-container {
  padding: 2em 7vw;
  align-items: flex-start;
}

div.project-container hr {
  margin: 0.5em 0;
}

p.project-title {
  font-weight: 600;
  font-size: 1.25em;
  margin-bottom: 0;
}

p.project-client {
  margin-top: 1em;
  margin-bottom: 0;
}

p.project-skills {
  font-size: 0.85em;
}

img.project-pic {
  width: 100%;
  border-radius: 0.5em;
  box-shadow: 0px 0px 16px 5px rgba(210, 212, 217, 1);
}

div.project-header {
  padding: 1em 7vw;
  margin-top: 1.5em;
}

div.project-header h4 {
  margin-bottom: 0;
  padding: 0.5em 0;
}

div.project-header hr {
  margin-bottom: 0;
}

div.project-links {
  display: flex;
}

a.project-link {
  margin-right: 1em;
}

ul.achievement-list {
  padding: 0 0.5em;
}

ul.achievement-list li {
  font-size: 0.75em;
}
